<template>
    <b-row>

        <b-col
                cols="12"
                md="12"
                class=" mb-1"
        >

            <div class="table-container-wrap">


                <div v-if="role == 'consultant' && auth_user.role == 'admin'" class="mb-1 text-right">
                    <b-button @click.prevent="$emit('choseAllowedUsers',{role:role})"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-primary"
                              class=""
                    >
                        {{ $t('label_allowed_users') }}
                    </b-button>
                </div>

                <b-table

                        :ref="role+'_TABLE'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="columnsDefs"
                        primary-key="uniq"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                        foot-clone

                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>


                    <template #cell(edit)="data">

                        <b-form-checkbox v-if="!data.item.is_core"

                                         value="1"
                                         unchecked-value="0"
                                         v-model="data.item.edit"
                                         @change="updatePermission(data.item, 'edit');"
                        >

                        </b-form-checkbox>

                    </template>
                    <template #cell(view)="data">

                        <b-form-checkbox v-if="!data.item.is_core"
                                         value="1"
                                         unchecked-value="0"
                                         v-model="data.item.view"
                                         @change="updatePermission(data.item, 'view');"
                        >

                        </b-form-checkbox>

                    </template>

                    <template #foot()="data">
                        <i>{{ '' }}</i>
                    </template>
                    <template #foot(view)="data">
                        <b-form-checkbox
                                value="1"
                                unchecked-value="0"
                                v-model="checkAllView"
                                @change="updateAll(checkAllView, 'view');"
                        >

                        </b-form-checkbox>

                    </template>
                    <template #foot(edit)="data">
                        <b-form-checkbox
                                value="1"
                                unchecked-value="0"
                                v-model="checkAllEdit"
                                @change="updateAll(checkAllEdit, 'edit');"
                        >

                        </b-form-checkbox>

                    </template>
                    <template #foot(permission_name)="data">
                        {{$t('label_select_all')}}

                    </template>
                </b-table>
            </div>

        </b-col>

    </b-row>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BButton, BAvatar, BTooltip, BFormInput, BFormRadio

    } from 'bootstrap-vue'

    import VueUploadComponent from 'vue-upload-component'

    import {
        MODULE_PREFIX,
        MODULE_PREFIX as PREFIX,
    } from './moduleHelper'

    export default {
        components: {

            BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BButton, BAvatar, BTooltip, BFormInput,
             VueUploadComponent, BFormRadio
        },

        props: ['permissions', 'permNames', 'role', 'auth_user'],
        data() {
            return {

                PREFIX,
                MODULE_PREFIX,

                tabItem: false,

                columnsDefs: [
                    // {label: '№', key: 'id', thClass: 'hidden', tdClass: 'hidden'},
                    {label: 'label_module', key: 'permission_name', sortable: false},
                    {label: 'label_permission_view', key: 'view', sortable: false},
                    {label: 'label_permission_edit', key: 'edit', sortable: false},
                ],

                isBusy: false,
                tableItems: [],
                tableTotal: 0,
                tableData: {
                    currentPage: 1,
                    perPage: 1000,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    // perPageOptions: [10, 25, 50, 100],
                },

                checkAllView: 1,
                checkAllEdit: 1,


            }
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            locale: function(newVal){
                this.prepareItems();
            }
        },
        computed:{
            locale: function(){
                return  this.$store.state.app.currentLocale;
            },
        },

        methods: {

            prepareItems(){
// console.log(this.permNames);
                let items = [];
                let uniq = 1;
                for(let moduleKey in this.permNames){

                    let view = 0;
                    let edit = 0;
                    let perm_name = '';
                    let key = '';
                    let is_core = false;


                    for(let permKey in this.permNames[moduleKey]){

                        key = moduleKey+'.'+permKey; view = 0; edit = 0; is_core = false;

                        if(permKey == 'core') {
                            is_core = true;
                            edit = this.permissions[key+'.edit'];
                            view = this.permissions[key+'.view'];
                            perm_name = this.$t(this.permNames[moduleKey][permKey].toLowerCase().replace(/ /g,"_")).ucFirst();
                        } else {
                            is_core = false;
                            edit = this.permissions[key+'.edit'];
                            view = this.permissions[key+'.view'];
                            perm_name =  this.$t(this.permNames[moduleKey].core.toLowerCase().replace(/ /g,"_")).ucFirst() + ' - ' +  (this.permNames[moduleKey][permKey] ? this.$t(this.permNames[moduleKey][permKey].toLowerCase().replace(/ /g,"_")) : '--');
                        }
                        if(view == 0 || !view){
                            this.checkAllView = 0;
                        }
                        if(edit == 0 || !edit){
                            this.checkAllEdit = 0;
                        }

                        items.push({uniq: uniq, permission_name: perm_name, module:this.role, permission: key, view: view, edit: edit, is_core: is_core});
                        uniq++;
                    }

                }

                 this.tableItems = items;


            },

            updatePermission(item, action) {

                let permSections = item.permission.split('.');
                let parentPerm = permSections[0];
                let formData = new FormData();
                formData.append(item.module + '[0][permission]', item.permission+'.'+action);
                formData.append(item.module + '[0][value]', item[action]);

                if(!item[action] || item[action] == 0){
                    let coreCheck = false;
                    let permGroupName = item.permission.split('.')[0];
                    for(let perm in this.permNames[permGroupName]){

                      if(perm == 'core') continue;

                      if(permGroupName + '.' + perm != item.permission && this.permissions[permGroupName + '.' + perm + '.' + action] == 1) {
                        coreCheck = true; break;
                      }

                    }
                    formData.append(item.module + '[1][permission]',  parentPerm+'.core.'+action);
                    formData.append(item.module + '[1][value]', coreCheck? 1 : 0);
                } else {
                    formData.append(item.module + '[1][permission]', parentPerm+'.core.'+action);
                    formData.append(item.module + '[1][value]', 1);
                }


                this.async('post', '/' + MODULE_PREFIX ,  formData, function (res) {
                    this.$emit('refreshData', {});
                }, false,  {headers:{'Content-Type': 'multipart/form-data'}});
            },

            updateAll(value, action) {
                this.confirmAction(this.$t('label_permissions'), this.$t('label_permissions_confirm'),'warning',(result) => {
                    let formData = new FormData();

                    for(let i = 0; i<this.tableItems.length; i++){
                        // this.tableItems[i][action] = value;
                        formData.append(this.role + '['+i+'][permission]', this.tableItems[i].permission+'.'+action);
                        formData.append(this.role + '['+i+'][value]', value);
                    }

                    this.async('post', '/' + MODULE_PREFIX ,  formData, function (res) {
                        this.$emit('refreshData', {});
                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}});
                }, (error) => { this['checkAll' + action.ucFirst()] = !this['checkAll' + action.ucFirst()];});

            }
        },

        created() {
            this.prepareItems();
        },
    }
</script>