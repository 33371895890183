<template>
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            size="lg"
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            {{$t('label_allowed_users')}}
        </template>

        <template #default="{ hide }">
            <!-- Form -->
            <validation-observer
                    #default="{ handleSubmit, invalid }"
                    :ref="PREFIX + '_FORM'"
                    tag="form"
                    class="p-1"
            >

                    <!--***********INFINITY**************************************************************************************8-->
                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_allowed_users')"
                            rules=""
                            slim
                    >
                        <b-form-group :label="$t('label_allowed_users')"
                        >
                            <!--:query-params="{extra_search:'default'}"-->
                            <infinity-scroll
                                    selected-prop="id"
                                    :multiple="true"
                                    url="/user"
                                    :placeholder="$t('label_select')"
                                    :close-on-select="false"
                                    v-model="itemData[adding_user_type]"
                                    :default-selection="chosenUsers"
                            >
                                <template #label="{item}">{{ item.name }}</template>

                            </infinity-scroll>

                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>
                    <!--***********INFINITY**************************************************************************************8-->

                <div class="d-flex justify-content-end mt-2">

                    <button class=" btn btn-default" @click.prevent="$bvModal.hide(modalRef? modalRef : PREFIX + '-modal')">
                        {{$t('label_close')}}
                    </button>

                    <button type="submit" @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                            class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_edit'):$t('label_submit')}}
                    </button>
                </div>

            </validation-observer>


        </template>
    </b-modal>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    // import {requiredAtLeastOne} from '@validations'
    // import { quillEditor } from 'vue-quill-editor'
    import {
        BFormTextarea,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupPrepend,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        // BBadge,
        // BProgress
    } from 'bootstrap-vue'
    // import vSelect from 'vue-select'
    // import  fileInput from '@/views/components/fileUploadInput'
    import  infinityScroll from '@/views/components/infinityScroll'

    // import {serialize} from 'object-to-formdata';

    // import { AGREEMENT_PREFIX, DEAL_PREFIX, TASK_PREFIX as PREFIX, CONTRACT_REG_PREFIX} from './../moduleHelper'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            // vSelect,
            // fileInput,
            infinityScroll,

        },
        props:['processedrole', 'adding_user_type'],
        data() {
            return {
                PREFIX: 'allowed-users',

                checkboxes: [],

                action: 'adding',

                blankItemData: {
                    [this.adding_user_type]: [],
                    role: this.processedrole,
                    type: this.adding_user_type,
                },

                itemData: {},
                chosenUsers: [],

            }
        },

        methods: {
            initData() {
                // if (this.editedItem) {
                //     this.action = 'editing';
                //     let item = Object.assign({}, this.blankItemData);
                //     item.id = this.editedItem.id;
                //     for (let prop in item) {
                //         if (this.editedItem.hasOwnProperty(prop)) {
                //             item[prop] = this.editedItem[prop];
                //         }
                //     }
                //     this.itemData = item;
                // } else {
                // alert('init default2: ', this.processedrole);
                // alert('init default2: ', this.adding_user_type);
                this.action = 'adding';
                this.itemData = Object.assign({}, this.blankItemData);
                this.itemData.role = 'consultant'; //todo
                // alert('init default: ', this.blankItemData.role);

                this.async('get', '/role_access_users/1', {params:{role:this.itemData.role, type:this.adding_user_type}}, function (res) {
                    this.chosenUsers = res.data;
                    let chosenIds = [];
                    for(let i = 0; i < this.chosenUsers.length; i++){
                        chosenIds.push(this.chosenUsers[i].id);
                    }
                    this.itemData[this.adding_user_type] = chosenIds;
                    // alert(this.processedrole);
                });

                // alert('init: ', this.itemData.role);
                // console.log('this.itemData',this.itemData);
                // alert(this.itemData.role);
                // }

            },
            resetForm() {
                this. chosenUsers = [];
                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {
                // alert(this.itemData.role);
                // alert('submit: ', this.itemData.role);
                // console.log(this.itemData.role); throw 1;
                if (this.action == 'adding') {
                    this.async('post', '/role_access_users', this.itemData, function (resp) {
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                }
                // else {
                //     this.async('put', '/agreement_' + this.PREFIX + 's/' + this.itemData.id, this.itemData, function (resp) {
                //         this.$emit('item-edited', {});
                //         this.$bvModal.hide(this.PREFIX + '-modal');
                //     });
                //
                // }

            },

        },

        // created() {
        //
        // }
    }
</script>